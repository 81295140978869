import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Alert, Form } from "react-bootstrap";
import { axiosConfig } from "../axiosAPI";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
// import { Formik, Form, Field } from "formik";
import { InputSwitch } from "primereact/inputswitch";

function User() {
  const [customers, setCustomers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "http://dog.addictaco.com/api/v2/users/passengers",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      setCustomers(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };
  useEffect(() => {
    getData().then((data) => {
    setLoading(false);
    });
  }, []);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const headerElement2 = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Edit Driver</span>
    </div>
  );
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add Driver</span>
    </div>
  );
  const footerContent = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          handleSubmit();
          setVisible(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );
  const footerContent2 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible2(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          editUser();
          setVisible2(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );

  const [formData, setFormData] = useState({
    PhoneNumber: "",
    FullName: "",
    Password: "",
    Dogs: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleDogChange = (index, e) => {
    const { name, value } = e.target;
    const newDogs = [...formData.Dogs];
    newDogs[index] = { ...newDogs[index], [name]: value };
    setFormData({ ...formData, Dogs: newDogs });
  };

  const handleDogFileChange = (index, e) => {
    const newDogs = [...formData.Dogs];
    newDogs[index] = {
      ...newDogs[index],
      [e.target.name]: e.target.files[0],
      PhotoFile: e.target.files[0],
      photofile: e.target.files[0],
      // [e.target.name]: "GFHFGHFGHFGH",
      // PhotoFile: "eFGHJFGHFGT",
    };
    setFormData({ ...formData, Dogs: newDogs });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const response = await axiosConfig
      .post(
        "http://dog.addictaco.com/api/v2/users/passengers/create",
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "multipart/form-data",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
      setFormData("");
  };

  const [items, setItems] = useState([]);
  const addItemToList = () => {
    const list = document.getElementsByClassName("dogs");

    const listItem = document.createElement("div");
    listItem.innerText = `
    
    `;

    // list.push(listItem);
    setItems((listItem) => [...listItem, list]);
  };

  const [indexRow, setIndexRow] = useState(false);

  const handleItemClick = (selectedIndex) => {
    // The magic of onClick - updating the selected item and visual cues!
    setIndexRow(selectedIndex);
  };

  const blockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`http://dog.addictaco.com/api/v2/users/${uuid}/block`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const unBlockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`http://dog.addictaco.com/api/v2/users/${uuid}/unblock`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const editUser = async (uuid) => {
    // wait for 1 second before deleting
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await axiosConfig
      .put(
        "http://dog.addictaco.com/api/v2/users/passengers/" +
          formData?.uuid +
          "/update",
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "multipart/form-data",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const statusAction = (rowData) => {
    return (
      <>
        <div className="action-table">
          <a
            className="edit"
            onClick={(e) => {
              setVisible(true);
              setFormData(rowData);
            }}
          >
            <i className="fa fa-pen"></i>
          </a>
          {rowData.stateLocalized == "Enabled" ? (
            <a
              className="delete"
              onClick={(e) => {
                blockUser(rowData.uuid);
              }}
            >
              <i className="fa fa-ban"></i>
            </a>
          ) : (
            <a
              className="delete"
              onClick={(e) => {
                unBlockUser(rowData.uuid);
              }}
            >
              <i className="fa fa-unlock"></i>
            </a>
          )}
        </div>
      </>
    );
  };



  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="table">
              <a className="add" onClick={() => setVisible(true)}>
                <i className="fa fa-plus"></i>
              </a>
              <DataTable
                value={customers}
                editMode="row"
                paginator
                showGridlines
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "700px" }}
                loading={loading}
                //   dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "fullName",
                  "phoneNumber",
                  "stateLocalized",
                  "dateCreated",
                ]}
                header={header}
                emptyMessage="No customers found."
                onRowEditComplete={onRowEditComplete}
                dataKey="uuid"
                cellSelection
                selectionMode="single"
                onSelectionChange={(e) => {
                  setSelectedCell(e.value);
                  setSelectedItem(e.value.value);
                }}
              >
                <Column
                  field="fullName"
                  header="user name"
                  style={{ width: "16.66666666%" }}
                ></Column>
                <Column
                  field="phoneNumber"
                  header="phone number"
                  style={{ width: "16.66666666%" }}
                ></Column>
                <Column
                  field="dateCreated"
                  header="date Join"
                  style={{ width: "16.66666666%" }}
                ></Column>
                <Column
                  field="dateLastLogin"
                  header="date Last Login"
                  style={{ width: "16.66666666%" }}
                ></Column>
                <Column
                  field="stateLocalized"
                  header="state"
                  style={{ width: "16.66666666%" }}
                ></Column>
                <Column
                  header=""
                  style={{ width: "16.66666666%" }}
                  field="uuid"
                  body={statusAction}
                ></Column>
                {/*  new column for actions (edit & delete) */}
              </DataTable>
              <Dialog
                visible={visible}
                modal
                header={headerElement}
                footer={footerContent}
                style={{ width: "50rem" }}
                onHide={() => {
                  if (!visible) return;
                  setVisible(false);
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="FullName"
                          placeholder="Saqer"
                          value={formData.FullName}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="tel"
                          name="PhoneNumber"
                          placeholder="01143987449"
                          value={formData.PhoneNumber}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>password</Form.Label>
                        <Form.Control
                          type="password"
                          name="Password"
                          placeholder="****"
                          value={formData.Password}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <div className="dogs">
                        <a
                          href="#!"
                          className="add-dogs"
                          onClick={() => addItemToList()}
                        >
                          + Add Dogs
                        </a>
                      </div>
                      {items.map((item, idx) => (
                        <div
                          key={idx}
                          className=""
                          onClick={() => handleItemClick(idx)}
                        >
                          <div className="addDogs row">
                            <div className="col-md-6">
                              <Form.Group className="form-group">
                                <Form.Label>Dog Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="name"
                                  value={formData.Dogs[idx]?.name || ""}
                                  onChange={(e) => handleDogChange(idx, e)}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-6">
                              <Form.Group className="form-group">
                                <Form.Label>Dog license</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="license"
                                  placeholder="8638389"
                                  value={formData.Dogs[idx]?.license || ""}
                                  onChange={(e) => handleDogChange(idx, e)}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-6">
                              <Form.Group className="form-group">
                                <Form.Label>Dog Age</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="age"
                                  placeholder="8"
                                  value={formData.Dogs[idx]?.age || ""}
                                  onChange={(e) => handleDogChange(idx, e)}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-6">
                              <Form.Group className="form-group">
                                <Form.Label>photo File</Form.Label>
                                <Form.Control
                                  id="photoFile"
                                  className="file"
                                  type="file"
                                  name="photoFile"
                                  onChange={(e) => handleDogFileChange(idx, e)}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Form>
              </Dialog>
              <Dialog
                visible={visible2}
                modal
                header={headerElement2}
                footer={footerContent2}
                style={{ width: "50rem" }}
                onHide={() => {
                  if (!visible2) return;
                  setVisible2(false);
                }}
              >
                <Form onSubmit={editUser}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="fullName"
                          placeholder="Saqer"
                          value={formData.fullName}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="tel"
                          name="phoneNumber"
                          placeholder="01143987449"
                          value={formData.phoneNumber}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default User;