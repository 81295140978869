import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { useAuth } from "../AuthProvider";
 // Assuming you're using a custom auth provider

const Test = () => {
  const auth = useAuth();
  const [driverLocation, setDriverLocation] = useState({ latitude: 0, longitude: 0 });
  const [passengerLocation, setPassengerLocation] = useState({ latitude: 0, longitude: 0 });
 // Assuming you get the auth token from here

  useEffect(() => {
  

    // Initialize Pusher without subscribing yet
    const pusher = new Pusher("a5e38fab4a807c0eac56", {
      cluster: "eu",
      encrypted: true,
    });

    // Bind to the 'connected' event to get the socket_id
    pusher.connection.bind('connected', async () => {
      const socketId = pusher.connection.socket_id;
      try {
        // Send the socket_id and channel_name in the POST body
        const response = await fetch(
          "http://dog.addictaco.com/api/v2/auth/pusher",
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + auth.token,
              "Content-Type": "application/json",
              "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
            },
            body: JSON.stringify({
              SocketId: socketId, // The socket_id provided by Pusher
              ChannelName: "private-ride_e84b72ad-87cd-4f8c-9c7d-1a1d3365a77b", // The channel name
            }),
          }
        );
        if (!response.ok) {
          throw new Error('Failed to authenticate with Pusher');
        }
        
        const data = await response.json();
     

        // Now subscribe to the channel with proper authentication
        // const channel = pusher.subscribe('private-ride_e84b72ad-87cd-4f8c-9c7d-1a1d3365a77b');
        const channel = pusher.subscribe('ride_e84b72ad-87cd-4f8c-9c7d-1a1d3365a77b');

        channel.bind('driver-location', (data) => {
          console.log("data",data)
          setDriverLocation({ latitude: data.latitude, longitude: data.longitude });
        });

        channel.bind('passenger-location', (data) => {
          console.log(data)
          setPassengerLocation({ latitude: data.latitude, longitude: data.longitude });
        });

        return () => {
          channel.unbind_all();
          channel.unsubscribe();
        };

      } catch (error) {
        console.error("Pusher authentication failed:", error);
      }
    });
  }, [driverLocation]);

  return (
    <div>
      <h2>Driver Location</h2>
      <p>Latitude: {driverLocation.latitude}</p>
      <p>Longitude: {driverLocation.longitude}</p>

      <h2>Passenger Location</h2>
      <p>Latitude: {passengerLocation.latitude}</p>
      <p>Longitude: {passengerLocation.longitude}</p>
    </div>
  );
};

export default Test;