import React, { useState, useRef, useEffect } from "react";
import Pusher from "pusher-js";
import { useAuth } from "../AuthProvider";

function DetailsRequests(props) {
      const auth = useAuth();
      const googleMapRef = useRef(null);
      const [map, setMap] = useState(null);

      // const [driverLocation, setDriverLocation] = useState({
      //   latitude: 0,
      //   longitude: 0,
      // });
      // const [passengerLocation, setPassengerLocation] = useState({
      //   latitude: 0,
      //   longitude: 0,
      // });
      const [driverMarker, setDriverMarker] = useState(null);
      const [passengerMarker, setPassengerMarker] = useState(null);

      const [driverLocation, setDriverLocation] = useState({
        latitude: props?.item?.latitudeFrom || 0,
        longitude: props?.item?.longitudeFrom || 0,
      });
      const [passengerLocation, setPassengerLocation] = useState({
        latitude: props?.item?.latitudeTo || 0,
        longitude: props?.item?.longitudeTo || 0,
      });


    useEffect(() => {
      // Initialize Pusher without subscribing yet
      const pusher = new Pusher("a5e38fab4a807c0eac56", {
        cluster: "eu",
        encrypted: true,
      });

      // Bind to the 'connected' event to get the socket_id
      pusher.connection.bind("connected", async () => {
        const socketId = pusher.connection.socket_id;
        try {
          // Send the socket_id and channel_name in the POST body
          const response = await fetch(
            "http://dog.addictaco.com/api/v2/auth/pusher",
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + auth.token,
                "Content-Type": "application/json",
                "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
              },
              body: JSON.stringify({
                SocketId: socketId, // The socket_id provided by Pusher
                ChannelName: `private-ride_${props?.item?.uuid}`, // The channel name
              }),
            }
          );
          if (!response.ok) {
            throw new Error("Failed to authenticate with Pusher");
          }

          const data = await response.json();

          // Now subscribe to the channel with proper authentication
          // const channel = pusher.subscribe('private-ride_e84b72ad-87cd-4f8c-9c7d-1a1d3365a77b');
          const channel = pusher.subscribe(`ride_${props?.item?.uuid}`);

          // channel.bind("driver-location", (data) => {
          //   console.log("data", data);
          //   setDriverLocation({
          //     latitude: data.latitude,
          //     longitude: data.longitude,
          //   });
          // });

          // channel.bind("passenger-location", (data) => {
          //   console.log(data);
          //   setPassengerLocation({
          //     latitude: data.latitude,
          //     longitude: data.longitude,
          //   });
          // });

          channel.bind("driver-location", (data) => {
            setDriverLocation({
              latitude: data.latitude,
              longitude: data.longitude,
            });
          });

          // Listen for passenger-location updates
          channel.bind("passenger-location", (data) => {
            setPassengerLocation({
              latitude: data.latitude,
              longitude: data.longitude,
            });
          });

          return () => {
            channel.unbind_all();
            channel.unsubscribe();
          };
        } catch (error) {
          console.error("Pusher authentication failed:", error);
        }
      });
    }, [driverLocation]);

      console.log(props?.item?.latitudeFrom);
      console.log(props?.item?.longitudeFrom);
      console.log(props?.item?.latitudeTo);
      console.log(props?.item?.longitudeTo);
      

      useEffect(() => {
        const googleMap = initGoogleMap();
        setMap(googleMap);
      }, []);


      // useEffect(() => {
      //   if (!map) return;

      //   var directionsService = new window.google.maps.DirectionsService();
      //   var directionsRenderer = new window.google.maps.DirectionsRenderer();
      //   var haight = new window.google.maps.LatLng(
      //     props?.item?.latitudeFrom,
      //     props?.item?.longitudeFrom
      //   );
      //   var oceanBeach = new window.google.maps.LatLng(
      //     props?.item?.latitudeTo,
      //     props?.item?.longitudeTo
      //   );
      //   var request = {
      //     origin: haight,
      //     destination: oceanBeach,
      //     travelMode: "WALKING",
      //   };
      //   directionsService.route(request, function (response, status) {
      //     if (status == "OK") {
      //       directionsRenderer.setDirections(response);
      //       directionsRenderer.setMap(map);
      //     }
      //   });
      // }, [map]);

      useEffect(() => {
        if (!map) return;

        // Update driver marker
        if (driverMarker) {
          driverMarker.setPosition({
            lat: driverLocation.latitude,
            lng: driverLocation.longitude,
          });
        } else {
          const marker = new window.google.maps.Marker({
            position: {
              lat: driverLocation.latitude,
              lng: driverLocation.longitude,
            },
            map,
            label: "D", // Label for driver
            title: "Driver Location",
          });
          setDriverMarker(marker);
        }

        // Update passenger marker
        if (passengerMarker) {
          passengerMarker.setPosition({
            lat: passengerLocation.latitude,
            lng: passengerLocation.longitude,
          });
        } else {
          const marker = new window.google.maps.Marker({
            position: {
              lat: passengerLocation.latitude,
              lng: passengerLocation.longitude,
            },
            map,
            label: "P", // Label for passenger
            title: "Passenger Location",
          });
          setPassengerMarker(marker);
        }
      }, [map, driverLocation, passengerLocation]);


      const initGoogleMap = () => {
        return new window.google.maps.Map(googleMapRef.current, {
          center: new window.google.maps.LatLng(
            props?.item?.latitudeFrom,
            props?.item?.longitudeFrom
          ),
          zoom: 8,
        });
      };

  return (
    <>
      <div ref={googleMapRef} className="google-map" />
    </>
  );
}

export default DetailsRequests;
