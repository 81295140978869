import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { axiosConfig } from "../axiosAPI";
import { Alert, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
// import { Formik, Form, Field } from "formik";
import { InputSwitch } from "primereact/inputswitch";

function Promo() {
  const [customers, setCustomers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "http://dog.addictaco.com/api/v1/promo-codes",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      setCustomers(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };
  useEffect(() => {
    getData().then((data) => {
    setLoading(false);
    });
  }, []);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const headerElement2 = (
    <div className="d-flex align-items-center justify-content-between gap-2">
      <span className="font-bold white-space-nowrap">Edit Promo Code</span>
    </div>
  );
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add Promo Code</span>
    </div>
  );
  const footerContent = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          handleSubmit();
          setVisible(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );
  const footerContent2 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible2(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          editUser();
          setVisible2(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );

  const [formData, setFormData] = useState({
    code: "",
    // discountPercentage: "",
    maxDiscountValue: "",
    startDate: "",
    endDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const data = {
      ...formData,
      discountPercentage: state // Add phone number to JSON
    };
    const response = await axiosConfig
      .post("http://dog.addictaco.com/api/v1/promo-codes", data, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
      setFormData("");
  };

  const deleteUser = async (uuid) => {
    // wait for 1 second before deleting
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await axiosConfig
      .delete("http://dog.addictaco.com/api/v1/promo-codes/" + uuid, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const editUser = async (uuid) => {
    // wait for 1 second before deleting
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await axiosConfig
      .put(
        "http://dog.addictaco.com/api/v1/promo-codes/" + formData?.uuid,
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const statusAction = (rowData) => {
    return (
      <>
        <div className="action-table">
          <a
            className="edit"
            onClick={(e) => {
              setVisible2(true);
              setFormData(rowData);
            }}
          >
            <i className="fa fa-pen"></i>
          </a>
          <a
            className="delete"
            onClick={(e) => {
              deleteUser(rowData.uuid);
            }}
          >
            <i className="fa fa-trash"></i>
          </a>
        </div>
      </>
    );
  };


  
  const [state, setState] = useState("");
  const handleDisChange = (e) => {
    const { value } = e.target;
    if (value && +value > 100) return setState(100);
    setState(+value);
  };

  // const state = formData.discountPercentage

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="table">
              <a className="add" onClick={() => setVisible(true)}>
                <i className="fa fa-plus"></i>
              </a>
              <DataTable
                value={customers}
                editMode="row"
                paginator
                showGridlines
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "700px" }}
                loading={loading}
                //   dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "code",
                  "discountPercentage",
                  "maxDiscountValue",
                  "startDate",
                  "endDate",
                ]}
                header={header}
                emptyMessage="No customers found."
                onRowEditComplete={onRowEditComplete}
                dataKey="uuid"
                cellSelection
                selectionMode="single"
                onSelectionChange={(e) => {
                  setSelectedCell(e.value);
                  setSelectedItem(e.value.value);
                }}
              >
                <Column
                  field="code"
                  header="code"
                  style={{ width: "16.66666666%" }}
                ></Column>
                <Column
                  field="discountPercentage"
                  header="discount Percentage"
                  style={{ width: "16.66666666%" }}
                ></Column>
                <Column
                  field="maxDiscountValue"
                  header="max Discount Value"
                  style={{ width: "16.66666666%" }}
                ></Column>
                <Column
                  field="startDate"
                  header="start Date"
                  style={{ width: "16.66666666%" }}
                ></Column>
                <Column
                  field="endDate"
                  header="end Date"
                  style={{ width: "16.66666666%" }}
                ></Column>
                <Column
                  header=""
                  style={{ width: "16.66666666%" }}
                  field="uuid"
                  body={statusAction}
                ></Column>
                {/*  new column for actions (edit & delete) */}
              </DataTable>
              <Dialog
                visible={visible}
                modal
                header={headerElement}
                footer={footerContent}
                style={{ width: "50rem" }}
                onHide={() => {
                  if (!visible) return;
                  setVisible(false);
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                          type="text"
                          name="code"
                          placeholder="Promo 55"
                          value={formData.code}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Discount Percentage</Form.Label>
                        <Form.Control
                          type="number"
                          max="100"
                          step="any"
                          name="discountPercentage"
                          placeholder="discountPercentage"
                          value={state}
                          onChange={handleDisChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Max Discount</Form.Label>
                        <Form.Control
                          type="number"
                          name="maxDiscountValue"
                          placeholder="maxDiscountValue"
                          value={formData.maxDiscountValue}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          step={2}
                          name="startDate"
                          placeholder="startDate"
                          value={formData.startDate}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          step={2}
                          placeholder="endDate"
                          value={formData.endDate}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </Dialog>
              <Dialog
                visible={visible2}
                modal
                header={headerElement2}
                footer={footerContent2}
                style={{ width: "50rem" }}
                onHide={() => {
                  if (!visible2) return;
                  setVisible2(false);
                }}
              >
                <Form onSubmit={editUser}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                          type="text"
                          name="code"
                          placeholder="Promo 55"
                          value={formData.code}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Discount Percentage</Form.Label>
                        <Form.Control
                          type="number"
                          name="discountPercentage"
                          placeholder="discountPercentage"
                          value={formData.discountPercentage}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Max Discount</Form.Label>
                        <Form.Control
                          type="number"
                          name="maxDiscountValue"
                          placeholder="maxDiscountValue"
                          value={formData.maxDiscountValue}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          step={2}
                          name="startDate"
                          placeholder="startDate"
                          value={formData.startDate}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          step={2}
                          placeholder="endDate"
                          value={formData.endDate}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Promo;