import React, { useState, useEffect } from "react";
import "../Assets/Css/login.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";

function Login() {
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();

  const auth = useAuth();


  // const loginUser = async () => {
  //     return axios
  //       .post(
  //         "http://dog.addictaco.com/api/v2/auth/authenticate",
  //         {
  //           phoneNumber: credentials.phone,
  //           password: credentials.password,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
  //           },
  //         }
  //       )
  //       .then((data) => {
  //         return data.data;
  //       })
  //       .catch(function (error) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "error",
  //           text: error.response.data.title,
  //         });
  //       });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phone !== "" && password !== "") {
      auth.loginAction({
          phoneNumber: phone,
          password: password
      });
      return;
    }
  };


  return (
    <>
      <div className="login">
        <div className="login-card">
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3" >
              <input
                className="form-control"
                id="phone"
                name="phone"
                type="number"
                placeholder="Phone Number"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="form-group mb-3">
              <input
                className="form-control"
                id="Password"
                name="Password"
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button className="submit" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
