import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Table from "../Table/Table";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { axiosConfig } from "../axiosAPI";
import { useNavigate } from "react-router-dom";
import { Loader } from "@googlemaps/js-api-loader";
import GMap from "./GMap";

function DetailsRequests(params) {
  const location = useLocation();
  const [post, setPost] = useState({});
  useEffect(() => {
    setPost(location.state.item);
  }, []);
  const auth = useAuth();
  const navigate = useNavigate();


const GOOGLE_MAP_API_KEY = "AIzaSyAUqawhSTbic6Pi1nerf01QZK2uImvqhCg";
const [loadMap, setLoadMap] = useState(false);

useEffect(() => {
  const options = {
    apiKey: GOOGLE_MAP_API_KEY,
    version: "weekly",
    libraries: ["places"],
  };

  new Loader(options)
    .load()
    .then(() => {
      setLoadMap(true);
    })
    .catch((e) => {
      console.error(
        "Sorry, something went wrong: Please try again later. Error:",
        e
      );
    });
}, []);


  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="details">
              {!loadMap ? <div>Loading...</div> : <GMap item={post} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailsRequests;
