import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { axiosConfig } from "../axiosAPI";
import { FilterMatchMode, FilterOperator } from "primereact/api";

function Hours() {
  const auth = useAuth();
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "http://dog.addictaco.com/api/v2/rush-hours",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      setData(response.data.data);
      // console.log(response.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const [formData, setFormData] = useState({
    dayOfWeek: "",
    timeFrom: "",
    multiplier: "",
    timeTo: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();  
    try {
      const response = await axiosConfig.post(
        "http://dog.addictaco.com/api/v2/rush-hours",
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      getData();
      console.log(response.data);
      } catch (error) {
      console.error( error);
      }
      setFormData("");
    };

  const editUser = async (x) => {
    // e.preventDefault();  
    try {
      const response = await axiosConfig.put(
        `http://dog.addictaco.com/api/v2/rush-hours/${x.uuid}`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      getData();
      console.log(response.data);
      } catch (error) {
      console.error( error);
      }
    };

  const deleteUser = async (x) => {
    // wait for 1 second before deleting
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await axiosConfig
      .delete(`http://dog.addictaco.com/api/v2/rush-hours/${x.uuid}`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const [visible, setVisible] = useState(false);
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add Rush Hours</span>
    </div>
  );
  const footerContent = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        className="save"
        onClick={(event) => {
          setVisible(false);
          handleSubmit();
        }}
        autoFocus
      />
    </div>
  );


  useEffect(() => {
    getData()
  }, []);

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="table">
              <a className="add" onClick={() => setVisible(true)}>
                <i className="fa fa-plus"></i>
              </a>
              <div className="">
                <div className="accordion mt-5" id="accordionExample">
                  {data.map((x, index) => (
                    <section key={index}>
                      <div className="accordion-item">
                        <h2
                          key={index}
                          className={x.completed ? "task completed" : "task"}
                          id="headingOne"
                        >
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            {x.dayOfWeek}
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <span>
                              {x.timeFrom} - {x.timeTo} (x {x.multiplier}){" "}
                              <button onClick={() => deleteUser(x)}>x</button>
                              <button
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <i className="fa fa-pen"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-xl">
                          <div className="modal-content">
                            <div className="modal-body">
                              <form>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>Day</label>
                                      <select
                                        value={formData.dayOfWeek}
                                        onChange={handleChange}
                                        name="dayOfWeek"
                                        className="form-select"
                                      >
                                        <option value={"Saturday"}>
                                          Saturday
                                        </option>
                                        <option value={"Sunday"}>Sunday</option>
                                        <option value={"Monday"}>Monday</option>
                                        <option value={"Tuesday"}>
                                          Tuesday
                                        </option>
                                        <option value={"Wednesday"}>
                                          Wednesday
                                        </option>
                                        <option value={"Thursday"}>
                                          Thursday
                                        </option>
                                        <option value={"Friday"}>Friday</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>From Time</label>
                                      <input
                                        className="form-control"
                                        type="time"
                                        name="timeFrom"
                                        id="timeFrom"
                                        value={formData.timeFrom}
                                        onChange={handleChange}
                                        step="2"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>To Time</label>
                                      <input
                                        className="form-control"
                                        type="time"
                                        name="timeTo"
                                        step="2"
                                        id="timeTo"
                                        value={formData.timeTo}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>Multiplier</label>
                                      <input
                                        type="number"
                                        step={0.1}
                                        value={formData.multiplier}
                                        onChange={handleChange}
                                        name="multiplier"
                                        placeholder="x2"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="modal-footer modal-button">
                              <button
                                type="button"
                                className="cancel"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                data-bs-dismiss="modal"
                                onClick={(event) => {
                                  editUser(x);
                                }}
                                className="save"
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  ))}
                </div>

                <Dialog
                  visible={visible}
                  modal
                  header={headerElement}
                  footer={footerContent}
                  style={{ width: "50rem" }}
                  onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                  }}
                >
                  <form>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Day</label>
                          <select
                            value={formData.dayOfWeek}
                            onChange={handleChange}
                            name="dayOfWeek"
                            className="form-select"
                          >
                            <option value={"Saturday"}>Saturday</option>
                            <option value={"Sunday"}>Sunday</option>
                            <option value={"Monday"}>Monday</option>
                            <option value={"Tuesday"}>Tuesday</option>
                            <option value={"Wednesday"}>Wednesday</option>
                            <option value={"Thursday"}>Thursday</option>
                            <option value={"Friday"}>Friday</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>From Time</label>
                          <input
                            className="form-control"
                            type="time"
                            name="timeFrom"
                            id="timeFrom"
                            value={formData.timeFrom}
                            onChange={handleChange}
                            step="2"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>To Time</label>
                          <input
                            className="form-control"
                            type="time"
                            name="timeTo"
                            step="2"
                            id="timeTo"
                            value={formData.timeTo}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Multiplier</label>
                          <input
                            type="number"
                            step={0.1}
                            value={formData.multiplier}
                            onChange={handleChange}
                            name="multiplier"
                            placeholder="x2"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hours;
